import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  promoStatus:boolean;
  constructor( 
  ){
    console.log(window);
    if (window == top) { // current window == window.top?
       console.log('not in iF');
       this.promoStatus = true;
    } else {
      console.log('in If');
      this.promoStatus = false;
    }
    
  }

 
}
