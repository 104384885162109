import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { Auth } from "aws-amplify";
import { Hub } from "aws-amplify";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Router,ActivatedRoute,NavigationEnd } from "@angular/router";
import {awsmobile} from '../../environments/environment'
import Amplify from "aws-amplify";
import { env } from 'process';
//import awsconfig from "./aws-exports";
//Amplify.configure(awsconfig);
// Amplify.configure(awsmobile);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class LoginComponent implements OnInit {
  options: string[] = ["Development", "Staging", "Qa","Production"];
  selectedEnvironment:any;
  displayUserName:any;
  userView: boolean;
  user: any;
  title = "amplify-app";
  errorMessage: any = "";
  private mapEntries: any = [
    ["Screen name does not exist", /user.*not.*exist/i],
    ["Screen name already exists", /user.*already.*exist/i],
    ["Incorrect screen name or password", /incorrect.*username.*password/i],
    ["Invalid password format", /validation.*password/i],
    ["Please enter password.", /Custom.*pool/i],
    ["Incorrect screen name.", /Only.*supported/i],
    [
      "Invalid phone number format",
      /invalid.*phone/i,
      "Invalid phone number format. Please use a phone number format of +12345678900",
    ],
  ];
  signedIn: boolean;
  greeting: string;
  userName: any;
  userId: any;
  screenName: any;
  userToken: any;
  
  constructor(
    private amplifyService: AmplifyService,
    private spinnerService: Ng4LoadingSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    let localEnv = window.localStorage.getItem('env');
    if (localEnv){
      this.selectEnvironment(localEnv);
      this.selectedEnvironment = localEnv;
    } else {
      this.selectEnvironment('Staging');
      this.selectedEnvironment = 'Staging';
    }

    // this.selectedEnvironment = 'Staging';
    var storageLength = window.localStorage.length;
    if (storageLength > 3) {
      this.userView = false;
    } else {
      this.userView = true;
    }

    this.amplifyService.authStateChange$.subscribe((authState) => {
      this.signedIn = authState.state === "signedIn";
      if (!authState.user) {
        this.userView = true;
        this.user = null;
      } else {  
        this.userView = false;
        this.user = authState.user.storage;
        this.userToken = authState.user.signInUserSession.idToken.jwtToken;
        this.userName = authState.user.username;
        this.displayUserName = authState.user.attributes.preferred_username;
        this.userId = authState.user.attributes["custom:userId"];
      }
    });

    Hub.listen("auth", (data) => {
      if(data){
        this.listener(data);
      }
    });
   }

  ngOnInit() {
   
  }

  
  handleSignout() {
    this.router.navigate(['/signout']);
  }

 
  callLogin() {
    const user: any = document.getElementById("username");
    const pswd: any = document.getElementById("password");
    try {
      if (user.value.trim() == "") {
        this.errorMessage = "Please enter screen name.";
      } else if (pswd.value == "") {
        this.errorMessage = "Please enter password.";
      } else {
        this.spinnerService.show();
        Auth.signIn(user.value.trim(), pswd.value);
      }
    } catch (error) {
      console.log("error signing in", error);
    }
  }

  clearFields(){
    const user: any = document.getElementById("username");
    const pswd: any = document.getElementById("password");
    user.value = "";
    pswd.value = "";
  }

  listener(data) {
    this.errorMessage = this.messageMap(data && data.payload && data.payload.data && data.payload.data.message);
    this.spinnerService.hide();
  }

  messageMap(message) {
    const match = this.mapEntries.filter((entry) => entry[1].test(message));
    if (match.length === 0) {
      return message;
    }
    this.spinnerService.hide();
    const entry = match[0];
    const msg = entry.length > 2 ? entry[2] : entry[0];
    return msg;
  }

  handleClipboard(){
      var copyTextarea = document.querySelector('.tokenInfo') as HTMLInputElement ;
      copyTextarea.select();
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
      } catch (err) {
        console.log('Oops, unable to copy');
      }
  }


  selectEnvironment(env){
    if(env == "Development"){
      window.localStorage.setItem('env', env);

      // awsmobile.aws_user_files_s3_bucket = "amplify-salespadweb-staging-122353-deployment",
      // awsmobile.aws_user_files_s3_bucket_region = "us-east-1",
      // awsmobile.aws_appsync_region = "us-east-1",
      // awsmobile.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS",
      // awsmobile.aws_appsync_apiKey = "null",
      // awsmobile.aws_project_region = "us-east-1",
      // awsmobile.aws_cognito_region = "us-east-1",
      // awsmobile.aws_appsync_graphqlEndpoint =
      // "https://3uyupjzvbvb6dpdts6msvwjfy4.appsync-api.us-east-1.amazonaws.com/graphql",
      // awsmobile.oauth = {}
  
      awsmobile.aws_cognito_identity_pool_id = "us-east-1:50c0679d-57eb-473d-a830-7913850af280";
      awsmobile.aws_user_pools_id  = "us-east-1_fjWGqZMP1";
      awsmobile.aws_user_pools_web_client_id = "1cs2gg1a757fu5e22bejfu6m8h";
    }
    if(env == "Staging"){
      window.localStorage.setItem('env', env);

      // awsmobile.aws_user_files_s3_bucket = "amplify-salespadweb-staging-122353-deployment",
      // awsmobile.aws_user_files_s3_bucket_region = "us-east-1",
      // awsmobile.aws_appsync_region = "us-east-1",
      // awsmobile.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS",
      // awsmobile.aws_appsync_apiKey = "null",
      // awsmobile.aws_project_region = "us-east-1",
      // awsmobile.aws_cognito_region = "us-east-1",
      // awsmobile.aws_appsync_graphqlEndpoint =
      // "https://3uyupjzvbvb6dpdts6msvwjfy4.appsync-api.us-east-1.amazonaws.com/graphql",
      // awsmobile.oauth = {}


      awsmobile.aws_cognito_identity_pool_id = "us-east-1:50c0679d-57eb-473d-a830-7913850af280";
      awsmobile.aws_user_pools_id  = "us-east-1_fjWGqZMP1";
      awsmobile.aws_user_pools_web_client_id = "1cs2gg1a757fu5e22bejfu6m8h";
    }
    if(env == "Qa"){
      window.localStorage.setItem('env', env);

      // awsmobile.aws_user_files_s3_bucket = "amplify-salespadweb-staging-122353-deployment",
      // awsmobile.aws_user_files_s3_bucket_region = "us-east-1",
      // awsmobile.aws_appsync_region = "us-east-1",
      // awsmobile.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS",
      // awsmobile.aws_appsync_apiKey = "null",
      // awsmobile.aws_project_region = "us-east-1",
      // awsmobile.aws_cognito_region = "us-east-1",
      // awsmobile.aws_appsync_graphqlEndpoint =
      // "https://3uyupjzvbvb6dpdts6msvwjfy4.appsync-api.us-east-1.amazonaws.com/graphql",
      // awsmobile.oauth = {}


      awsmobile.aws_cognito_identity_pool_id = "us-east-1:5c6612ca-3604-4cdd-94f8-4954d846665c";
      awsmobile.aws_user_pools_id  = "us-east-1_3OqH3aDJf"
      awsmobile.aws_user_pools_web_client_id = "17spppok77qiakrbul758sudk3";
    }
    if(env == "Production"){
      window.localStorage.setItem('env', env);

      // awsmobile.aws_user_files_s3_bucket = "amplify-salespadweb-staging-122353-deployment",
      // awsmobile.aws_user_files_s3_bucket_region = "us-east-1",
      // awsmobile.aws_appsync_region = "us-east-1",
      // awsmobile.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS",
      // awsmobile.aws_appsync_apiKey = "null",
      // awsmobile.aws_project_region = "us-east-1",
      // awsmobile.aws_cognito_region = "us-east-1",
      // awsmobile.aws_appsync_graphqlEndpoint =
      // "https://3uyupjzvbvb6dpdts6msvwjfy4.appsync-api.us-east-1.amazonaws.com/graphql",
      // awsmobile.oauth = {}

    
      awsmobile.aws_cognito_identity_pool_id = "us-east-1:e22a23c9-0f34-42fd-ad6f-a548649f8c06";
      awsmobile.aws_user_pools_id  = "us-east-1_WPhcg35bJ";
      awsmobile.aws_user_pools_web_client_id = "65arl0n1r50mhlfbevgrd1c7qu";
    }
    Amplify.configure(awsmobile);
  }


}
