import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";

import { HttpClientModule } from "@angular/common/http";

//Components
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';


import {  UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from '../services/UrlSerializer';

@NgModule({
  declarations: [AppComponent, LoginComponent, LogoutComponent],
  imports: [
    BrowserModule, 
    AppRoutingModule, 
    AmplifyAngularModule,
    HttpClientModule,
    Ng4LoadingSpinnerModule,
    FormsModule
  ],
  providers: [AmplifyService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
