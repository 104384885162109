export const environment = {
  production: false,
};

export const awsmobile = {
  aws_user_files_s3_bucket: "amplify-salespadweb-staging-122353-deployment",
  aws_user_files_s3_bucket_region: "us-east-1",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "null",
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:50c0679d-57eb-473d-a830-7913850af280",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_fjWGqZMP1",
  aws_user_pools_web_client_id: "1cs2gg1a757fu5e22bejfu6m8h",
  aws_appsync_graphqlEndpoint:
    "https://3uyupjzvbvb6dpdts6msvwjfy4.appsync-api.us-east-1.amazonaws.com/graphql",
  oauth: {}
};

